<template lang="pug">
  
.home(id="ecosystem-list-group")
  
    .row
      .col-3(id="searchmenu_orient")
          .sidemenu-search(id="searchmenu")   
            b-overlay(:show="$store.getters.loading" size="sm", top)
              SearchFilter().mr-3
  
      .col-9(v-if="$store.getters.search.rows")
        
        .ml-2
          template(v-if="$route.name=='ecosystem_list'")
            .row
                .col-12
                    .float-right
                        b-form-group(label="Sort", label-for="sort-select" label-cols="3")
                          b-select(:options="sort_options", v-model="sortBy", style="max-width: 150px", size="sm", @change="getDataClear()")#sort-select
            
          .row.mt-1   
              .col-12
                  template(v-if="$route.name=='ecosystem_benchmark'")
                    div(style="max-width: 1000px")
                      BenchmarkScatter(v-model="rows")

                  template(v-if="$route.name=='ecosystem_comparison'")
                 
                    div(style="overflow-x: auto; overflow-y: none; min-height: calc(100vh - 300px)")
                      table.table.table-sm.table-responsive.compare-table.table-bordered(v-if="rows && rows.length > 0", style="min-height: calc(100vh - 300px)")
                        tr()
                          td.desc()
                            b-form-group(label="").full-width
                              b-dropdown(id="dropdown-right" right text="Data Fields" variant="outline-secondary" size="sm").w-100
                                b-dropdown-form(v-for="att in attributes_options", style="min-width: 300px")
                                  b-check(:value="att", v-model="attributes_selected") {{att.label}}
                          td(v-for="(item, index) in rows").text-center
                             .media
                                      
                              b-check(@change="toggle_selected(item)", :checked="$store.getters.selectedItems.map(x=>x._id).indexOf(item._id) > -1").mr-2
                              
                              router-link(:to="'/ecosystem/orgs/' + item._id")
                                  div(class="feed-image-small" :style="'background: url(https://images.hytechnologies.co/unsafe/50x50/logo.clearbit.com/' + item.web_entity + ') center 0% / cover no-repeat;'").mr-2
                          
                              .media-body 
                               
                                h6.p-0.m-0 
                                    router-link(:to="'/ecosystem/orgs/'+item._id").nowrap {{item.name}}

                                     
                        tr(v-for="at in attributes_selected")
                          td.desc() {{at.label}}
                          td(v-for="(item, index) in rows").p-0
                            .text-center
                              template(v-if="rows[index]['custom_data'][at.name]")
                                .indicator.p-2(:style="{backgroundColor: heatMapColorforValue(calc_data(rows[index]['custom_data'][at.name], at.name))}")
                                  b(v-if="!isNaN(rows[index]['custom_data'][at.name])") {{$auth.get_formatted(rows[index]['custom_data'][at.name], at)}}
                                  b(v-if="isNaN(rows[index]['custom_data'][at.name]) && rows[index]['custom_data'][at.name]").text-clamp {{rows[index]['custom_data'][at.name].toString()}}
                        
                        tr(v-for="(v,k) in instance_config.organization_kpis_short")
                          td.desc() {{v}}
                          td(v-for="(item, index) in rows").text-center 
                            b(v-if="isNaN(item[k])") {{item[k]}}
                            b(v-else-if="item[k]") {{item[k].toString().formatPrice()}}
                            b(v-else) -
                                                    
                        tr() 
                          td.desc() Total funding
                          td(v-for="(item, index) in rows").text-center 
                            span(v-if="rows[index].total_funding_usd") {{rows[index]["total_funding_usd"].toString().formatPrice()}}

                       
                        tr() 
                          td.desc() Founding year
                          td(v-for="(item, index) in rows").text-center {{rows[index]["founding_date"] | moment("YYYY")}}

                        tr()
                          td.desc() Location
                          td(v-for="(item, index) in rows").text-center {{rows[index]["city"]}}<br/>{{rows[index]["country_code"]}}
                    .small.mt-3.text-gray(v-if="$store.getters.search.total_rows > 30").mb-3 Your search results to more than 30 entries, we just show the first 30 entries in the comaprison view. Add additional filters to narrow your search. 
              
                  template(v-if="$route.name=='ecosystem_list'")
                    b-list-group()
                        b-list-group-item( v-for="(item, index) in $store.getters.search.rows").checkboxed

                            .row.mt-1.p-1

                                .col-md-12
                                    .media
                                      
                                        b-check(@change="toggle_selected(item)", :checked="$store.getters.selectedItems.map(x=>x._id).indexOf(item._id) > -1").mr-2
                                        router-link(:to="'/ecosystem/orgs/' + item._id")
                                            div(class="feed-image" :style="'background: url(https://images.hytechnologies.co/unsafe/50x50/logo.clearbit.com/' + item.web_entity + ') center 0% / cover no-repeat;'").mr-3
                                    
                                        .media-body 
                                            .row()
                                              .col-lg-8.col-md-12
                                                
                                                h5.p-0.m-0 
                                                    router-link(:to="'/ecosystem/orgs/'+item._id") {{item.name}}

                                                div.links()
                                                    a(:href="'https://' + item.web_entity" target="_blank"  v-if="item.web_entity").mr-1.small {{item.web_entity}}
                                                    a(:href="item.homepage_url" target="_blank" v-else-if="item.homepage_url").mr-1.small {{item.homepage_url}}
                                            
                                                span.small(v-if="item.city") {{item.city}}, {{item.country_code}}
                                                span.small(v-if="!item.city") {{item.address}} 
                                                                        
                                                .small.text-muted(style="max-width: 800px") {{item.short_description}}
                                                .small.text-muted(style="max-width: 800px") {{item.description}}
                                                .small.text-muted(style="max-width: 800px") {{item.web_text}}

                                                .badge.badge-secondary(v-for="tag in item.tags").mr-1 {{tag}}
                                                
                                                .small.text-muted(style="max-width: 800px", v-if="!item.short_description") {{item.registration_subject}}

                                                .row.mt-2
                                                  
                                                  .col(v-for="(v,k) in instance_config.organization_kpis_short").d-flex.justify-content-between.align-items-center
                                                    div.text-center
                                                      b(v-if="isNaN(item[k])") {{item[k]}}
                                                      b(v-else-if="item[k]") ${{item[k].toString().formatPrice()}}
                                                      b(v-else) -
                                                      .small {{v}}

                                              .col-lg-4.col-md-12
                                              
                                                .small.text-muted(v-if="item.source_first_created_at") Founded {{ item.founding_date | moment("YYYY-MM")}}
                                                .small.text-muted(v-if="item.source_first_created_at") Added {{ item.source_first_created_at | moment("YYYY-MM-DD")}} 
                                                span(v-if="item.is_startup_score")
                                                  label Startup score
                                                  .badge.badge-secondary().ml-1 {{item.is_startup_score | round }}
                                                
                                                span(v-if="item.relations && item.relations.contact_ids && item.relations.contact_ids.length")
                                                  label.mr-2.mt-3.d-block Contacts
                                                  
                                                  .mb-1(v-for="contact in item.relations.contact_ids.slice(0,3)")
                                                    .badge.badge-secondary
                                                      router-link(:to="'/ecosystem/orgs/' + item._id") {{contact.first_name}} {{contact.last_name}} <span v-if="contact.first_name || contact.last_name">&mdash;</span> {{contact.position}}
                                                
                                                div(v-if="item.index_space && item.index_space.projects")
                                                  label.mr-2.mt-1.d-block Projects
                                                  
                                                  .mb-1(v-for="po in item.index_space.projects")
                                                    router-link(:to="'/projects/' + po._id + '/board'", v-if="po._id")
                                                      .badge.badge-secondary  {{po.name}} 

                                              
                                                
                                                div(v-if="item.relations && item.relations.external")
                                                  .badge.badge-secondary External
                                                //.badge.badge-secondary.mr-1(v-for="it in item.category_list") {{it}}
                                                //.badge.badge-secondary.mr-1(v-for="it in item.category_groups_list") {{it}}
                    .row.mt-5
                      .col-12(v-if="$store.getters.search.rows")
                        infinite-loading(@infinite="getData")
                          <div slot="spinner">Loading...</div>
                          <div slot="no-more">No more results</div>
                          <div slot="no-results">No results found for your query.</div>
                      
                    
                          i.text-muted.small {{$store.getters.search.total_rows}} results found. All results are view- and exportable in production version.
                    
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import TaxonomyDisplay from '@/components/TaxonomyDisplay'
import BenchmarkScatter from '@/components/BenchmarkScatter'




import SearchFilter from '@/views/ecosystem/SearchFilter'
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'
import { mapFields } from 'vuex-map-fields';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  
  name: 'home',
  
  data: function () {
    return {
      currentPage: 1,
      s: {},

      attributes_options:  [],


      attributes_selected: [],
      q: "",
      query: "",
      uuid: null,
      event_results: [],
      sort_options: [{"text": "Last funding date", "value": "last_funding_on"}, {"text": "Total funding", "value": "total_funding_usd"},  {"text": "Entry Date", "value": "source_first_created_at"}, {"text": "Founding Date", "value": "founding_date"}, {"text": "Company Rank", "value": "hy_rank"}],

      selected: [],
      selectedNode: {},
      colors: {"retrieval": "rgba(200, 200, 200, 0.8)", "extraction": "rgba(200, 200, 200, 0.8)", "knowledge": "rgba(200, 200, 200, 0.8)", undefined: "rgba(200, 200, 200, 0.8)"},
      sizes: {"Org": 70, "Text": 10, "Topic": 20, "Website": 25, "Webpage": 15, "ImprintInfos": 10},
      current_actions: [{"label": "Remove", "name": "remove"}],
      graphName: "",

      sticky_options: {
        topSpacing: 120,
        resizeSensor: false,
        bottomSpacing: 100
       
      }
    }
  },
filters: {
      round: function(x) {
        if (!x) return '';
        return Math.round(x*100)/100;
      }
},
  mounted: function() {
  
    this.host = Config.config.base_host;
    this.attributes_options = this.instance_config.data_fields;
    this.attributes_selected = this.attributes_options;
   
  },

  created() {
   
  },

  beforeDestroy(){
   
  },


  computed: {
      ...mapFields([
        'query.filter.sortBy',
        'query.filter.bookmark_hy',
        'query.filter.bookmark_ecosystem',
        'search.rows',
        'instance_config'
      ]),

  },

  methods: {


    toggle_selected(item) {
      
      console.log(this.$store.getters.selectedItems.map((x)=>{return x._id}));
      if(this.$store.getters.selectedItems.map((x)=>{return x._id}).indexOf(item._id)>-1) {
        this.$store.dispatch("remove_selected", [item]);
      } else {
        this.$store.dispatch("add_selected", [item]);
      }
    },
    
    heatMapColorforValue(percentage, hue0 = 120, hue1 = 0) {
      var hue = ((1 - percentage) * (hue1 - hue0)) + hue0;
      return 'hsl(' + hue + ', 100%, 50%, 0.2)';
    },

    calc_data(value, field) {

      function scale(val, max, min) { return (val - min) / (max - min); }
      
      let all_val = this.rows.map(row => {
        if(row &&  row.custom_data && row.custom_data[field] != undefined) {
          return row.custom_data[field];
        }
      }).filter(x => x != null);

      let min = Math.min(...all_val);
      let max = Math.max(...all_val);
      
      return scale(value, max, min);
    
    },


    getData(state) {
        this.$store.dispatch("search", state);
        
    },

     getDataClear() {
        this.bookmark_hy = null;
        this.bookmark_ecosystem = null;
        this.$store.dispatch("search");
       
        //this.display();
    },

  },
   directives: {
    "sticky": VueStickyDirective
  },
  components: {
      InfiniteLoading,
      SearchFilter,
      TaxonomyDisplay,
      BenchmarkScatter
  }
}

</script>
<style lang="scss" scoped>


.text-clamp {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}


.badge.badge-secondary {
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 300px;
  display: inline-block;
  position: relative;
}
 
.compare-table {
  font-size: 0.875em;
  td {
    width: 150px !important;
    min-width: 150px !important;  
  }

  td.desc {
    width: 200px !important;
    min-width: 200px !important;
    padding: 5px; 
  }
  
}

</style>