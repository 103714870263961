<template lang="pug">
.main
  ProjectModal(ref="projectModal")
  CompanyModal(ref="companyModal")
  b-overlay(:show="downloadBusy" rounded="sm")
    template(#overlay)
      | Exporting the data ... This might take a minute.

    .sticky-top
      b-overlay(:show="downloadBusy" rounded="sm")
        template(#overlay)
          |  Downloading ... This might take a minute.
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu(v-if="!$route.query.extension")#submenu
            .container-xxl
                .row.no-gutters.submenu-inner.pt-3.mb-1
                   
                    .col-6
                      small.text-muted.align-self-center(v-if="loaded_query") {{loaded_query.name}}: 
                      small.text-muted.align-self-center(v-if="$store.getters.search.total_rows", style="margin-top: 2px") {{$store.getters.search.total_rows.toLocaleString(undefined)}} results found
                      span(v-if="!compareFilter()").text-muted  - 
                      a(variant="outline-secondary", size="sm", @click="query_name = ''; loaded_query = null; $route.query.saved_query_id=null; reset_all();  initFilter();", v-if="!compareFilter()", href="javascript:void(0);").text-muted.small Reset all filters
                    .col-6
                     
                      .float-right
                        b-dropdown.mr-2(id="ddclinical",  ref='dropdown', variant="outline-secondary", size="sm", right, v-if="saved_queries && saved_queries.length")
                          template(#button-content)
                              template(v-if="!loaded_query")
                                | Your Monitors
                              template(v-else)
                                .text-muted.d-inline-block.mr-1 Monitor: 
                                | {{ loaded_query.name }}
                          template(v-if="saved_queries == null")
                            b-dropdown-item
                              .text-muted Loading ...
                          div(style="max-height: 300px; overflow-y: scroll").p-0.m-0
                            template(v-for="q in saved_queries")
                              
                                b-dropdown-item(@click="do_load_query(q._id)")
                                    | {{q.name}}

                        b-dropdown.mr-2(id="ddclinical",  ref='dropdownsave', variant="outline-secondary", size="sm", right, no-caret, v-b-tooltip="(($store.getters.search.total_rows > 5000) ? 'Monitor needs less than 5000 results, please redefine your search.' : 'Save Monitor')", :disabled="compareFilter() || $store.getters.search.total_rows == 0 || $store.getters.search.total_rows > 5000")
                          template(#button-content)
                            | Save to Monitor
                          b-dropdown-form(right, style="min-width: 400px")
                            b-form(@submit.stop.prevent="do_save_query(query_name)")

                              b-input(type="text", placeholder="Search name", v-model="query_name", required, autocomplete="off")
                              .text-muted.small Change name to save as new monitor.
                              .text-danger.small(v-if="(!loaded_query || (loaded_query && loaded_query.name != query_name)) && saved_queries && saved_queries.find(x => x.name == query_name && !x.deleted_at)")
                                | Name already used. Please choose another name.
                              .mt-1
                                .float-right
                                  b-button-group()
                                    b-button(variant="outline-secondary", size="sm", @click="loaded_query='';$refs.dropdownsave.hide()")
                                      | Cancel
                                    b-button(variant="outline-primary", size="sm" :disabled="(!loaded_query || (loaded_query && loaded_query.name != query_name)) && saved_queries && saved_queries.find(x => x.name == query_name && !x.deleted_at)" type="submit")
                                      | Save
                
                .row.no-gutters.submenu-inner
                    .col-12
                      
                      b-form(

                      )
                        b-form-group()
                          b-input-group() 
                           
                            b-radio(name="query_type", v-model="query.filter.query_type", value="keywords").mr-2 Keywords
                            b-radio(name="query_type", v-model="query.filter.query_type", value="description") Description
                              // i.fa.fa-info-circle.text-muted.ml-2(title="", v-b-tooltip.hover)


                        b-input-group(id="keyword", v-if="query.filter.query_type == 'keywords'").w-100.mb-2.mt-2
                         
                          TopicKeywordSelect(v-model="query.filter.full_query", :query_mode="query.filter.query_mode", @query_mode_change="change_query_mode" @search="getData()", :showRecommendations="true", :embed="true", placeholder="Enter a keyword or category and press enter").small
                        
                        template(v-if="query.filter.query_type == 'description'")
                          b-input-group(id="description", description="").w-100.mb-2.mt-2

                            b-input(:rows="1", placeholder="Enter a description and press enter", v-model="query.filter.sim_description", @keydown.enter.prevent="enterDesc($event)")
                        
                          .small.mt-1.mb-3.text-muted Search Tip: Description may include: Market, Customers/Target group, Technology, Business Model

                        .mb-2 
                          
                          template(v-for="sm in instance_config.topic_scout_mapping")
                           
                            b-dropdown.mr-2(v-if="filter_topics_cat[sm.topic_id]",  ref='dropdown', variant="outline-secondary", size="sm")
                              template(#button-content)
                                    span(style="text-transform: none") {{sm.name}} 
                                    b-badge(style="min-width: 7em", v-if="query.filter['topic_sel_' + sm.topic_id]") {{"" + (query.filter['topic_sel_' + sm.topic_id].length==0 ? ("No filter") : (query.filter['topic_sel_' + sm.topic_id].length + "/" + filter_topics_cat[sm.topic_id].length)) + ""}}
                             
                              b-dropdown-form(style="min-width: 800px")
                                
                                b-row.my-1.small
                                 
                                  b-col(md="8")
                                  
                                    b-link(@click="query.filter['topic_sel_' + sm.topic_id]=$_.cloneDeep([]); getData();", v-show="query.filter['topic_sel_'+ sm.topic_id] && query.filter['topic_sel_' + sm.topic_id].length > 0") Reset filter
                                    .text-muted(v-show="!query.filter['topic_sel_'+ sm.topic_id] || query.filter['topic_sel_' + sm.topic_id].length == 0") Select topic to narrow search, click again to stricly exclude topic.
                                  b-col(md="4").text-right
                                    b-button-group
                                      b-input(v-model="filter_topics_name[sm.topic_id]", size="sm", type="search", placeholder="Filter")
                                        
                                b-row.mt-1
                                  b-col(md="12")
                                    
                                    div(style="column-count: 3;")
                                      div(:key="'d' + category._id" v-for="category in filter_topics_cat[sm.topic_id].map(x => ({'name': x.name, '_id': x._id, 'full_query': x.full_query})).filter(x => !filter_topics_name[sm.topic_id] || x.name.toLowerCase().startsWith(filter_topics_name[sm.topic_id].toLowerCase()) )")
                                        ThreeStateCheckbox(:key="category._id"  v-model="query.filter['topic_sel_' + sm.topic_id]", :topic="category._id", @input="getData()", :label="category.name")

                          b-dropdown.mr-2(id="ddorgtypes", :text='"Organizations (" + query.filter.org_types.length + "/" + company_type_options.length + ")"' ref='dropdown', variant="outline-secondary", size="sm")
                              template(#button-content)
                               
                                    span(style="text-transform: none") Organizations 
                                   
                                    b-badge(style="min-width: 7em; ") {{"" + (query.filter.org_types.length==0 ? ("No filter") : (query.filter.org_types.length + "/" + company_type_options.length)) + ""}}
                             
                              b-dropdown-form(style="min-width: 400px")
                                
                                b-row.my-2.small
                                  b-col(md="12")
                                    //b-link(@click="query.filter.org_types=$_.cloneDeep(company_type_options)") Select all
                                    
                                    b-link(@click="query.filter.org_types=$_.cloneDeep([])") Reset filter
                                b-row.mt-1
                                  b-col(md="6", v-for="category in company_type_options")
                                    
                                    ThreeStateCheckbox(v-model="query.filter.org_types", :topic="category.value", @input="getData()", :label="category.text") {{category}}
                          

                          b-dropdown#dropdown-form.mr-2(text='Advanced filter' ref='dropdown', variant="outline-secondary", size="sm",  right, @click="hideCheck")
                            template(#button-content)
                            
                              span(style="text-transform: none") Advanced filter 

                            b-dropdown-form(style="min-width: 800px")
                              
                              SearchFilterHealth(@click.native="handleClick", @search="getData()").mr-3

                         
                          .d-inline-block(v-if="loaded_query")
                            .d-flex.align-items-center.justify-content-center
                              .small.mr-1 In Monitor scope 
                              label.switch

                                input(type='checkbox' role='switch', v-model="$store.state.monitor_switch", @change="do_load_query($route.query.saved_query_id)")
                                  
                                
                                span.slider.round
                              .small.ml-2 Out of scope
                              .small.ml-3 
                                template(v-if="!$store.state.monitor_switch") Added 
                                template(v-else) Removed 
                                
                                | date range: 
                              input(type="date" v-model="$store.state.monitor_date_from" @change="do_load_query($route.query.saved_query_id)", style="border: 1px solid; padding: 2px").mr-1
                              .small &mdash; 
                              input(type="date" v-model="$store.state.monitor_date_to" @change="do_load_query($route.query.saved_query_id)", style="border: 1px solid; padding: 2px").ml-1

                              


                          .float-right
                            b-form-group(label="Sort", label-for="sort-select" label-cols="3")
                              
                              b-select(:options="sort_options", v-model="query.filter.sortBy", style="max-width: 150px", size="sm", @change="getData()")#sort-select
                          
  
                          
                          //div() 
                            b-dropdown.mr-2(v-if="filter_topics_therapeutical && instance_config.app_config.base.version == 'health'", id="ddclinical",  ref='dropdown', variant="outline-secondary", size="sm")
                              template(#button-content)
                                    span(style="text-transform: none") Clinical 
                                    b-badge(style="min-width: 7em") {{"" + (query.filter.therapeutical_topics.length==0 ? ("No filter") : (query.filter.therapeutical_topics.length + "/" + filter_topics_therapeutical.length)) + ""}}
                             
                              b-dropdown-form(style="min-width: 800px")
                                
                                b-row.my-1.small
                                 
                                  b-col(md="8")
                                    //b-link(@click="query.filter.therapeutical_topics=$_.cloneDeep(filter_topics_all)") Select all
                                    //| &nbsp; | &nbsp;
                                    b-link(@click="query.filter.therapeutical_topics=$_.cloneDeep([]); getData();", v-show="query.filter.therapeutical_topics.length > 0") Reset filter
                                    .text-muted(v-show="!query.filter.therapeutical_topics || query.filter.therapeutical_topics.length == 0") Select topic to narrow search, click again to stricly exclude topic.
                                  b-col(md="4").text-right
                                    b-button-group
                                      b-input(v-model="filter_topics_name_therapeutical", size="sm", type="text", placeholder="Filter")
                                        
                                b-row.mt-1
                                  b-col(md="12")
                                    
                                    div(style="column-count: 3;")
                                      div(:key="'d' + category._id" v-for="category in filter_topics_therapeutical.map(x => ({'name': x.name, '_id': x._id, 'full_query': x.full_query})).filter(x => !filter_topics_name_therapeutical || x.name.toLowerCase().startsWith(filter_topics_name_therapeutical.toLowerCase()) )")
                                        ThreeStateCheckbox(:key="category._id"  v-model="query.filter.therapeutical_topics", :topic="category._id", @input="getData()", :label="category.name")

                          

                            b-dropdown.mr-2(v-if="filter_topic_topics", id="ddclinical",  ref='dropdown', variant="outline-secondary", size="sm")
                              template(#button-content)
                                    span(style="text-transform: none", v-if="instance_config.app_config.base.version == 'health'") Topics 
                                    span(style="text-transform: none", v-if="instance_config.app_config.base.version != 'industries'") Industries 
                                    b-badge(style="min-width: 7em") {{"" + (query.filter.topic_topics.length==0 ? ("No filter") : (query.filter.topic_topics.length + "/" + filter_topic_topics.length)) + ""}}
                             
                              b-dropdown-form(style="min-width: 800px")
                                
                                b-row.my-1.small
                                  b-col(md="6")
                                
                                    b-link(@click="query.filter.topic_topics=$_.cloneDeep([]); getData();", v-show="query.filter.topic_topics.length > 0") Reset filter
                                  b-col(md="6").text-right
                                    b-button-group
                                      b-input(v-model="filter_topics_name_topics", size="sm", type="text", placeholder="Filter")
                                        
                                b-row.mt-1
                                  b-col(md="12")
                                    
                                    div(style="column-count: 3;")
                                      div(:key="'d' + category._id" v-for="category in filter_topic_topics.map(x => ({'name': x.name, '_id': x._id, 'full_query': x.full_query})).filter(x => !filter_topics_name_topics || x.name.toLowerCase().startsWith(filter_topics_name_topics.toLowerCase()) )")
                                        ThreeStateCheckbox(:key="category._id"  v-model="query.filter.topic_topics", :topic="category._id", @input="getData()", :label="category.name")

                                    
                            b-dropdown.mr-2(v-if="filter_topic_technology", id="ddclinical",  ref='dropdown', variant="outline-secondary", size="sm")
                              template(#button-content)
                                    span(style="text-transform: none") Technology 
                                    b-badge(style="min-width: 7em") {{"" + (query.filter.technology_topics.length==0 ? ("No filter") : (query.filter.technology_topics.length + "/" + filter_topic_technology.length)) + ""}}
                             
                              b-dropdown-form(style="min-width: 800px")
                                
                                b-row.my-1.small
                                  b-col(md="6")
                                
                                    b-link(@click="query.filter.technology_topics=$_.cloneDeep([]); getData();", v-show="query.filter.technology_topics.length > 0") Reset filter
                                  b-col(md="6").text-right
                                    b-button-group
                                      b-input(v-model="filter_topics_name_technology", size="sm", type="text", placeholder="Filter by name")
                                        
                                b-row.mt-1
                                  b-col(md="12")
                                    
                                    div(style="column-count: 3;")
                                      div(:key="'d' + category._id" v-for="category in filter_topic_technology.map(x => ({'name': x.name, '_id': x._id, 'full_query': x.full_query})).filter(x => !filter_topics_name_technology || x.name.toLowerCase().startsWith(filter_topics_name_technology.toLowerCase()) )")
                                        ThreeStateCheckbox(:key="category._id"  v-model="query.filter.technology_topics", :topic="category._id", @input="getData()", :label="category.name")

                        
                            b-dropdown.mr-2(id="ddorgtypes", :text='"Organizations (" + query.filter.org_types.length + "/" + company_type_options.length + ")"' ref='dropdown', variant="outline-secondary", size="sm")
                              template(#button-content)
                               
                                    span(style="text-transform: none") Organizations 
                                   
                                    b-badge(style="min-width: 7em; ") {{"" + (query.filter.org_types.length==0 ? ("No filter") : (query.filter.org_types.length + "/" + company_type_options.length)) + ""}}
                             
                              b-dropdown-form(style="min-width: 400px")
                                
                                b-row.my-2.small
                                  b-col(md="12")
                                    //b-link(@click="query.filter.org_types=$_.cloneDeep(company_type_options)") Select all
                                    
                                    b-link(@click="query.filter.org_types=$_.cloneDeep([])") Reset filter
                                b-row.mt-1
                                  b-col(md="6", v-for="category in company_type_options")
                                    
                                    ThreeStateCheckbox(v-model="query.filter.org_types", :topic="category", @input="getData()", :label="category") {{category}}
                          
                            b-dropdown#dropdown-form.mr-2(text='Advanced filter' ref='dropdown', variant="outline-secondary", size="sm"  right)
                              template(#button-content)
                               
                                span(style="text-transform: none") Advanced filter 

                              b-dropdown-form(style="min-width: 800px" )
                                
                                SearchFilterHealth(@click.native="handleClick").mr-3

                            .float-right
                              b-form-group(label="Sort", label-for="sort-select" label-cols="3")
                                
                                b-select(:options="sort_options", v-model="query.filter.sortBy", style="max-width: 150px", size="sm", @change="getData()")#sort-select
                
                .row.no-gutters.submenu-inner
                    .col-12

                        div(v-if="query.filter.query_type   == 'keywords' && $store.getters.search.spell_check && Array.isArray($store.getters.search.spell_check) && !$_.isEqual(this.$store.getters.search.spell_check, query.filter.full_query)").mb-3
                          | Did you mean? 
                          b-link(@click="query.filter.full_query=$store.getters.search.spell_check; getData()") {{$store.getters.search.spell_check.join( " " )}}
                        div(v-if="query.filter.query_type  == 'description' &&  $store.getters.search.spell_check && this.$store.getters.search.spell_check != query.filter.sim_description").mb-3
                          //| Did you mean? 
                          //b-link(@click="query.filter.sim_description=$store.getters.search.spell_check; getData()") {{$store.getters.search.spell_check}}
                    .col-12
                      div(style="border: 1px solid #ccc; border-bottom: none; background-color: white")
                        .p-1.px-3
                        
                          div(v-if="$store.getters.selectedItems && $store.getters.search.rows")
                            .row
                              .col-6

                                div.d-flex.flex-row.align-items-center 
                                  b-check(@change="select_all_none()", v-model="selectAll", :disabled="$store.getters.search.total_rows > 500").mr-2(style="margin-left: 7px")
                                    .text-muted.align-self-center(style="margin-top: 2px", v-b-tooltip="'Can only select less than 100 results at once.'", v-if="$store.getters.search.total_rows") Select all ({{$store.getters.search.total_rows.toLocaleString(undefined)}})
                                  
                                  template(v-if="$store.state.user.instance == 'hy' || $store.state.user.instance == 'development'")
                                    template(v-if="$store.getters.search.total_rows > 10000")
                                      .text-muted.small.align-self-center(style="margin-top: 2px", v-b-tooltip="'Max. 10.000 results can be downloaded. Please adjust your filter.'").ml-3 Download results
                                    template(v-else)
                                      b-link(@click="download(query)", size="sm", variant="outline-secondary", :disabled="$store.getters.search.total_rows > 10000", v-bind:class="{ 'text-muted': $store.getters.search.total_rows > 10000 }").small.ml-3 Download results
                                    
                              .col-6
                                div.float-right 
                                  .text-muted 
                                    b-link(@click="showModal()").small() 
                                      | +
                                      | Add company
                            

                //b-row(align-v="end").no-gutters.submenu-inner
                    .col-md-3
                        .menu
                        
                            router-link(to="/ecosystem/list").mr-5 List
                            //router-link(to="/ecosystem/map").mr-5 Map
                            router-link(to="/ecosystem/comparison").mr-5 Comparison
                            router-link(to="/ecosystem/benchmark" v-if="instance_config.app_config.base.version == 'dev354'").mr-5 Benchmark
                            //router-link(to="/ecosystem/funding").mr-5 Funding
                            // router-link(to="/scout/analysis").mr-5 Analysis
                            // router-link(to="/scout/network").mr-5 Network
                            // router-link(to="/scout/map").mr-5 Map.menu
             
                        
                    .col-md-7
                        //.result-card.checkboxed.selection(v-show="$store.getters.selectedItems.length").small
                            b-form
                                b-row(align-h="start")
                                    b-col( cols="3")
                                        b-check(variant="secondary", @change.native="select()", v-model="selectAll").d-inline-block(style="margin-left: 12px") 
                                        .d-inline-block(style="margin-top: 6px; line-height: 20px;  vertical-align: middle;").mr-2 Select all 
                                        label.d-inline-block(style="line-height: 20px;").mr-2 |
                                        .d-inline-block(style="margin-top: 6px; line-height: 20px;  vertical-align: middle;") {{ $store.getters.selectedItems.length }} selected 

                                    
                                    //.col-3
                                        b-select(:options="['Investment Pipeline: Review', 'Investment Pipeline: PoC']" size="sm")
                                    b-col( cols="9")
                                        b-input-group()
                                            ProjectStageSelect(v-model="selectedProjectStage", style="min-width: 200px", :create_option="false").mr-3
                                            b-btn(size="sm", @click="addCompaniesToProject($store.getters.selectedItems, selectedProjectStage)")#add-company-btn Add to project
                                    //.col-2
                                        b-btn(href="#", @click="addRecords()", size="sm", variant="outline-secondary").ml-3 Add to sheet
                                

    .container-xxl(class="d-flex flex-column")
          .row.no-gutters.work-view.sticky-container()
              
              b-col(:cols="sidebar_visible ? 9 : 12 ")
                #affix-container
                
                  router-view(name="main").main-view
              b-col(:cols="12-mainCols", v-if="mainCols < 12")
                  
                  .mt-5
                    .sidebar-right(v-bind:class="{'visible': sidebar_visible}", id="sideright").col-3
                      .float-right
                        b-btn(variant="link", @click="toggleSidebar()", size="sm")
                          i.fas.fa-arrow-right(v-show="sidebar_visible")
                          i.fas.fa-arrow-left(v-show="!sidebar_visible")
                      router-view(name="sideview").side-view(v-show="sidebar_visible")
    
    v-tour(name="myTour" :steps="steps")
  
</template>

<script>
import CompanyModal from "@/components/CompanyModal.vue";
import ProjectModal from "@/components/ProjectModal.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";
import ProjectStageSelect from "@/components/ProjectStageSelect.vue";
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import SearchFilterHealth from '@/views/ecosystem/SearchFilterHealth'
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'
import { mapFields } from 'vuex-map-fields'
import Config from "@/config";
import Vue from "vue";
import ThreeStateCheckbox from '@/components/ThreeStateCheckbox.vue'

export default {
  name: "SubmenuEcosystem",
  props: {
    title: String
  },

  data: function() {
    return {

      autoreload: true,
     
      steps: [
          {
            target: '#keyword',  // We're using document.querySelector() under the hood
            content: `Type keywords for `
          },
          {
            target: '#ddcategories',  // We're using document.querySelector() under the hood
            content: `Select health categories !`
          },
          {
            target: '#ddorgtypes',
            content: 'Select names'
          }
      ],
      
      downloadBusy: false,
      current_filter: null,
      saved_queries: null,
      query_name: null,
      full_query: [],
      filter_topics: null,
      loaded_query: null,
      loaded_query_stats: null,

      filter_topics_name: {},
      filter_topics_cat: {},

      filter_topics_name_therapeutical: null,
      filter_topics_name_topics: null,
      filter_topics_name_technology: null,

      filter_topics_therapeutical: null,
      filter_topic_topics: null,
      filter_topic_technology: null,
      sticky_options: {
          topSpacing: 100,
          bottomSpacing: 50 
      },
      mainCols: 9,
      sort_options: [{"text": "Last funding date", "value": "last_funding_on"}, {"text": "Total funding", "value": "total_funding_usd"},  {"text": "Founding Date", "value": "founding_date"}, {"text": "Company Rank", "value": "hy_rank"},  {"text": "Relevance", "value": "_score"}],
      categories_options: ["Alternative Medicine","Assisted Living","Assistive Technology","Biopharma","Cannabis","Child Care","Clinical Trials","Cosmetic Surgery","Dental","Diabetes","Dietary Supplements","Elder Care","Electronic Health Record (EHR)","Emergency Medicine","Employee Benefits","Fertility","First Aid","Genetics","Health Care","Health Diagnostics","Home Health Care","Hospital","Medical","Medical Device","mHealth","Nursing and Residential Care","Nutraceutical","Outpatient Care","Personal Health","Psychology","Rehabilitation","Therapeutics","Veterinary","Wellness"],
      company_type_options: [{value:"startup", text: "Startup"}, {value:"investor", text: "Investor"}, {value:"corporate", text: "Corporate"}, {value:"school", text: "School"}, {value:"Non-profits", text: "Non-profits"}, {value:"other", text: "Other"}], //["Startup", "Investor", "Corporation", "School", "Non-profits", "Other"],
      filterCols: 2,
      categories: [],
 
      offset_data: null,
      selectAll: false,
      selectedProjectStage: null
    };
  },

  mounted: function() {
   window.dispatchEvent(new Event('resize'));

   
    if(this.$route.query.saved_query_id) {
      this.do_load_query(this.$route.query.saved_query_id);
    }

    if(!this.query.filter.created) {
      this.initFilter();
    }

    this.set_filters();

    this.getQueries();
   
     if(this.$route.query.full_query) {
      this.initFilter();   
      this.query.filter.full_query = this.$route.query.full_query.split(",");
      this.$forceUpdate();
    }
  
    if(this.instance_config.app_config.base.version == 'health') {
        this.sort_options.find(x => x.value == 'hy_rank').value = "tags_rank";
    }


  },


  beforeMount() {

    this.handleDebouncedScroll = this.$_.debounce(this.handleScroll, 10);
    window.addEventListener('scroll', this.handleDebouncedScroll );  
    window.addEventListener('resize', this.handleDebouncedScroll);  
  },


  beforeDestroy() {

      window.removeEventListener('scroll', this.handleDebouncedScroll);
      window.removeEventListener('resize', this.handleDebouncedScroll);  
  },

  computed: {

      

        ...mapFields([
            'instance_config',
            'sidebar_visible',
            'query',
            'selectedItems'
        ]),
  },

  watch: {



    "query.filter.query_type": function(val) {
      
    
    

      if(val == "keywords") {
        this.query.filter.sim_description = null;
      }

      if(val == "description") {
        this.query.filter.full_query = [];
      }
     
    },

    instance_config: function() {
      
      this.set_filters();
      
    },

    selectedItems: function(val) {
   
      if(!val || val.length == 0) {
      
        this.selectAll = false;
       
      }
    },

    selectedProjectStage: function(value) {
      console.log(value);
      if (value == "new") {
        this.showProjectModal();
      }
    }
  
  
  },
  methods: {

    hideCheck(bvEvent) {
      console.log(bvEvent);
      bvEvent.preventDefault();  
    },

    change_query_mode: function(val) {
      this.query.filter.query_mode = val;
    },

    enterDesc(ev) {
      
      //this.query.filter.sim_description = this.query.filter.sim_description.replace("\n", " ");
      console.log( this.query.filter.sim_description);
      ev.preventDefault();
      this.getData();

    },


    set_filters() {
      let all = null;
      if(!this.instance_config.topic_scout_mapping) {
        if(!this.query.filter_init) this.query.filter_init = this.$_.cloneDeep(this.query.filter)
        return;
      } else {
        all = this.instance_config.topic_scout_mapping.map((entry) => {
        return Vue.http.post(`${Config.config.base_host}/topics_v1/search`, {"topic_type": "topic", "parent_id": [entry.topic_id]}).then(res => {
          Vue.set(this.query.filter, entry.topic_id, []);
          this.filter_topics_cat[entry.topic_id] = this.$_.orderBy(res.body.rows, ["name"]);
          
        });
      });
 
      }

      

      Promise.all(all).then(() => {
        if(!this.query.filter_init) this.query.filter_init = this.$_.cloneDeep(this.query.filter)
      });
      

    },

     select_all_none() {
      if(!this.selectAll) {
        this.$store.state.selectedItems = [];
      } else {
        this.$store.state.selectedItems = this.$_.cloneDeep(this.$store.getters.search.rows);
      }
      
      this.$forceUpdate();
    },

    compareFilter() {
      
      if(!this.query.filter_init) return false;
      const filtered1 = Object.keys(this.query.filter_init )
      .filter(key => !this.query.filter_compare_exclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.query.filter_init[key];
        return obj;
      }, {});

      const filtered2 = Object.keys(this.query.filter )
      .filter(key => !this.query.filter_compare_exclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.query.filter[key];
        return obj;
      }, {});

      let q  = this.$_.isEqual(filtered1, filtered2)
    
      return q;
    },

    initFilter() {
      
      Vue.set(this.query, "filter", this.$_.cloneDeep(this.$store.state.query.filter_base));
     
      if(this.instance_config.app_config.base.version == 'health') {
        this.sort_options.find(x => x.value == 'hy_rank').value = "tags_rank";

        this.query.filter.sortBy = "tags_rank";
      } else {
        this.query.filter.sortBy = "_score";
      }
      
      this.query.filter.add_name_to_full_query = true;
      this.query.filter.org_types = []; //this.$_.cloneDeep(this.company_type_options);
      this.query.filter.sources = ["hy"];
      
      
      this.query.filter.created = true;

      this.query.filter.bookmark_hy = null;
      this.query.filter.bookmark_ecosystem = null;
      
      this.set_filters();
     
      //this.getData(null, true);
      
      

      //this.$tours['myTour'].start()       
    },

    handleClick(ev) {
    
      ev.stopPropagation();
    },

     download(filter) {
       this.downloadBusy = true;
      
      Vue.http.post(`${Config.config.base_host}/organizations/bulk_export`, filter, { responseType: 'arraybuffer'}).then((resp) => {
       
        this.downloadFile("results.xlsx", resp.body);

        this.downloadBusy = false;
      }, () => {
        this.downloadBusy = false;
      }
      );

    
    },




    downloadFile(filename, dataValue) {
      window.URL = window.webkitURL || window.URL;
      window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder;

      var a = document.createElement('a');
      a.download =  filename;
      var bb = null;
      if (window.BlobBuilder == undefined) {
          bb = new Blob([dataValue], { 'type': "text/html" });
          a.href = window.URL.createObjectURL(bb);
      }
      else {
          bb = new window.BlobBuilder();
          bb.append(dataValue);
          a.href = window.URL.createObjectURL(bb.getBlob( "text/html"));
      }

      a.textContent = '';

      a.dataset.downloadurl = [ "application/octet-stream", a.download, a.href].join(':');
      a.draggable = true; // Don't really need, but good practice.
      a.classList.add('dragout');

      document.body.appendChild(a);

     

      a.click();
    },


    getData(state, force = false) {

       //this.$store.dispatch("start_loading");
       
        if(this.$store.state.load_query_mode) return;

        if(this.compareFilter()) {
          return;
        }
        
        if(this.current_filter && this.$_.isEqual(this.current_filter, this.query.filter) && !force) {
          return
        } 
       
        
        this.current_filter = this.$_.cloneDeep(this.query.filter);
        
        this.query.filter.bookmark_hy = null;
        this.query.filter.bookmark_ecosystem = null;

 
        this.$store.dispatch("search", state);
        //this.$store.dispatch("stop_loading");
        window.scrollTo(0,0);
        
    },

    toggleSidebar() {
      this.sidebar_visible = !this.sidebar_visible;
      window.dispatchEvent(new Event('resize'));
    },
    handleScroll() {

       
      let el = document.getElementById("submenu");

      if(el) {
        let rect = el.getBoundingClientRect();
        let sel = document.getElementById("sideright")
        sel.style.top = rect.bottom +"px";
        let searchsel = document.getElementById("searchmenu")
        let searchsel_orient = document.getElementById("searchmenu_orient")
        if(searchsel) {
         
          searchsel.style.top = rect.bottom +"px";
          searchsel.style.width = searchsel_orient.clientWidth + "px"
          //searchsel.style.height = "calc(100vh - " +searchsel.style.top + "px)"
        
        }
        
      }
      
    },

    addCompaniesToProject(selectedItem, selectedProjectStage) {
      let items = selectedItem.map(x => {
        return { organization_id: x._id, project_id: selectedProjectStage };
      });
      console.log(items, selectedProjectStage);
      this.$store.dispatch("start_loading");
      Vue.http
        .post(Config.config.base_host + "/project_organizations/bulk", items)
        .then(resp => {
          this.$store.dispatch("deselectAll");

           this.$bvToast.toast("Added " + resp.body.length + " companies to project", {
              title: 'Added',
              position: "bottom-right", 
              variant: 'primary',
              autoHideDelay: 3000,
            });

       
          this.$store.dispatch("stop_loading");
          this.$store.dispatch("search");
        });
    },

    showProjectModal() {
      let res = this.$refs.projectModal.open(null, "ecosystem");
      console.log(res);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    getQueries() { 
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/queries_v1`).then(res => {
        this.saved_queries = res.body;
        this.$store.dispatch("stop_loading");
      });
    },

    reset_all() {
        this.$router.replace({query: { "saved_query_id": "" }});
    },

    do_save_query(query_name) {
      this.$store.dispatch("start_loading");

      let method = "post";

      if(this.loaded_query && this.loaded_query._id && query_name == this.loaded_query.name) {
        method = "put";
      } 

      Vue.http[method](`${Config.config.base_host}/queries_v1` + (method === "put" ? "/" + this.loaded_query._id : ""), {"name": query_name, "filter": this.query.filter}).then(res => {
        this.$router.replace({query: { "saved_query_id": res.body._id }});
        this.getQueries();
        
        this.query_name=res.body.name;
        this.$refs.dropdownsave.hide();

        this.load_query = res.body._id;
        this.loaded_query = res.body;
        this.do_load_query(res.body._id);

         this.$bvToast.toast("Search \"" + query_name + "\" saved", {
              title: 'Saved',
              variant: 'primary',
                position: "bottom-right", 
              autoHideDelay: 1000,
         });
        
        this.$store.dispatch("stop_loading");
      });
    },


    do_load_query(query_id) {

      let old_query = this.$route.query.saved_query_id;

      this.$store.state.load_query_mode = true;
      this.$store.dispatch("start_loading");

      Vue.http.get(`${Config.config.base_host}/queries_v1/${query_id}`).then(res => {
        this.$store.state.monitor_page = 1;
        this.loaded_query = res.body;

        this.$router.replace({query: { "saved_query_id": res.body._id }});
        
        delete res.body.filter.bookmark_hy;
        delete res.body.filter.bookmark_ecosystem;

        if(res.body.filter.q) {
          this.use_query = true;
        }

        this.query_name = this.loaded_query.name;
        this.query.filter = res.body.filter;

        this.$store.dispatch("load_query", {query_id: query_id});

        
        setTimeout(() => {
          if(old_query != query_id) {
            this.$store.dispatch("search");
            
            this.$bvToast.toast("Search \"" + this.query_name + "\" loaded", {
                title: 'Loaded',
                variant: 'primary',
                position: "bottom-right", 
                autoHideDelay: 1000,
          });
            
          
            this.$store.state.load_query_mode = false;
          }
        }, 100);
       
        this.$store.dispatch("stop_loading");

        this.$forceUpdate();
        
        
      });

     


    },

    ucfirst(text) {
      if (!text) return text;
      return text[0].toUpperCase() + text.slice(1);
    },

    map(text) {
      return text[0].toUpperCase() + text.slice(1);
    },

    showModal() {
      this.$refs.companyModal.open();
    },

    showSidebar() {
      this.mainCols=(this.mainCols==12 ? 9 : 12); 
      this.$eventHub.$emit('resize');
    },

    hideSidebar() {
      this.mainCols=(this.mainCols==12 ? 9 : 12); 
      this.$eventHub.$emit('resize');
    },

    select() {
      if (this.selectAll) {
        this.$store.dispatch("selectAll");
      } else {
        this.$store.dispatch("deselectAll");
      }
    },



    addRecords: function() {
      this.$store.dispatch("addSelectedRecords");
    }
  },
   directives: {
    "sticky": VueStickyDirective
  },
  components: {
    CompanyModal,
    ProjectSelect,
    ProjectStageSelect,
    ProjectModal,
    TopicKeywordSelect,
    SearchFilterHealth,
    ThreeStateCheckbox
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_vars.scss";



/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $primary;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primary;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.filter-badge {
  padding: $pad/4;
  border: 1px solid $primary;
  color: $light;
  background-color: $primary;
  display: inline-block;
  opacity: 0.6;
}
</style>
