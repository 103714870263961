<template lang="pug">
  
.home(id="ecosystem-list-group")
    .row(style="margin-top: -31px")
      .col-12
         
          template(v-if="compareFilter()")
            .row.mt-5
              .col-3
              .col-6
                .text-center(v-if="instance_config.app_config.base.version == 'energy'")
                  h3 🔎 Scouting - Getting started.
                  .row.mt-5
                    .text-left
                        
                        | Use the <b>Keyword Search</b> to search to find results for queries, like:
                        li <a href="#" @click="searchIntro()">"Sustainability"</a>
                        li <a href="#" @click="query.filter.full_query = ['artificial intelligence', 'OR', 'ai','OR', 'machine learning']; getDataClear()">  "Artificial Intelligence" OR "AI" OR "Machine learning" </a>
                        li <a href="#" @click="query.filter.full_query = ['mobility', 'AND', 'hydrogen']; getDataClear()">  "Mobility" AND "Hydrogen"</a>
                        
                        .mt-5 Use the <b>Description Search</b> to find similar companies to your description, like:
                        li <a href="#" @click="query.filter.query_type='description'; query.filter.sim_description = 'A carbon offset platform that lets users trade credits'; getDataClear()"> "A carbon offset platform that lets users trade credits"</a>
                        li <a href="#" @click="query.filter.query_type='description'; query.filter.sim_description = 'Companies building infrastructure such as charging stations and hydrogen gas stations'; getDataClear()"> "Companies building infrastructure such as charging stations and hydrogen gas stations"</a>
                
                .text-center(v-else)
                  h3 🔎 Scouting - Getting started.
                  .row.mt-5
                    .text-left
                        
                        | Use the <b>Keyword Search</b> to search to find results for queries, like:
                        li <a href="#" @click="searchIntro()">"Sustainability"</a>
                        li <a href="#" @click="query.filter.full_query = ['artificial intelligence', 'OR', 'ai','OR', 'machine learning']; getDataClear()">  "Artificial Intelligence" OR "AI" OR "Machine learning" </a>
                        li <a href="#" @click="query.filter.full_query = ['transportation', 'AND', 'artificial intelligence']; getDataClear()">  "Transportation" AND "Artificial Intelligence"</a>
                        
                        .mt-5 Use the <b>Description Search</b> to find similar companies to your description, like:
                        li <a href="#" @click="query.filter.query_type='description'; query.filter.sim_description = 'A carbon offset platform that lets users trade credits'; getDataClear()"> "A carbon offset platform that lets users trade credits"</a>
                        li <a href="#" @click="query.filter.query_type='description'; query.filter.sim_description = 'Brands of frozen food like frozen yoghurt or frozen cocktails'; getDataClear()"> "Brands of frozen food like frozen yoghurt or frozen cocktails"</a>



          template(v-else)
            div(v-if="$store.getters.loading").text-center Loading ...
            b-overlay(:show="$store.getters.loading")
              
              h6.text-center.mt-5(v-if="$store.getters.search && $store.getters.search.rows && $store.getters.search.rows.length == 0") No results found for the query.
              b-list-group()
                
                  b-list-group-item(v-for="(item, index) in $store.getters.search.rows", :key="item._id").checkboxed

                      .row.mt-0.p-1
                          

                          .col-md-12.h-mouseover-parent
                              .media
                                  b-form-group()
                                    div.ml-auto.mr-auto
                                      b-check(@change="toggle_selected(item)", :checked="$store.getters.selectedItems.map(x=>x._id).indexOf(item._id) > -1").mr-2
                                    //div(style="margin-left: -5px").h-mouseover-show
                                      b-btn(size="sm", variant="link", @click="query.filter.sim_search.push(item.web_entity)").small.text-muted
                                        i.far.fa-thumbs-up
                                    //div
                                    
                                    //div(style="margin-left: -5px").h-mouseover-show
                                      b-btn(size="sm", variant="link", @click="query.filter.sim_search_neg.push(item.web_entity)").small.text-muted
                                        i.far.fa-thumbs-down

                                  router-link(:to="'/ecosystem/orgs/' + item._id")
                                      div(class="feed-image" :style="'background: url(https://images.hytechnologies.co/unsafe/50x50/logo.clearbit.com/' + item.web_entity + ') center 0% / cover no-repeat;'").mr-3
                              
                                  .media-body 
                                      .row()
                                        .col-lg-8.col-md-12
                                          
                                          h5.p-0.m-0 
                                              router-link(:to="'/ecosystem/orgs/'+item._id") {{item.name}}

                                          div.links()
                                              a(:href="'https://' + item.web_entity" target="_blank"  v-if="item.web_entity").mr-1.small {{item.web_entity}}
                                              a(:href="item.homepage_url" target="_blank" v-else-if="item.homepage_url").mr-1.small {{item.homepage_url}}
                                      
                                          span.small(v-if="item.city") {{item.city}}, {{item.country_code}}
                                          span.small(v-if="!item.city") {{item.address}} 
                                                                  
                                          .small.text-muted(style="max-width: 800px") {{item.short_description}}
                                          //.small.text-muted(style="max-width: 800px") 
                                          //.small.text-muted(style="max-width: 800px") {{item.description}}
                                          //.small.text-muted(style="max-width: 800px") {{item.web_text}}
                                          
                                          //.small.text-muted(style="max-width: 800px") {{item.org_types}}

                                          .badge.badge-primary.mr-1(v-for="it in item.org_types") {{ucfirst(it)}}

                                          .badge.badge-secondary(v-for="tag in item.tags", v-if="instance_config.organization_types.includes(tag)").mr-1 {{tag.replace('_', ' ')}}
                                          
              
                                          .small(v-if="item.relations.org_added_timestamp") Added to monitor: {{item.relations.org_added_timestamp | moment("YYYY-MM-DD") }}
                                          .small(v-if="item.relations.org_removed_timestamp") Removed from monitor: {{item.relations.org_removed_timestamp | moment("YYYY-MM-DD") }}

                                          .small.text-muted(style="max-width: 800px", v-if="!item.short_description") {{item.registration_subject}}

                                          .row.mt-2
                                            
                                            .col(v-for="(v,k) in instance_config.organization_kpis_short").d-flex.justify-content-between.align-items-center
                                              div.text-center
                                                b(v-if="isNaN(item[k])") {{item[k]}}
                                                b(v-else-if="item[k]") ${{item[k].toString().formatPrice()}}
                                                b(v-else) -
                                                .small {{v}}

                                        .col-lg-4.col-md-12
                                          //.small.text-muted(style="max-width: 800px",v-if="item.relations.sim || item.relations.sim == 0") Similarity {{item.relations.sim}}
                                          .small.text-muted(v-if="item.founding_date") Founded {{ item.founding_date | moment("YYYY-MM")}}
                                          .small.text-muted(v-if="item.last_funding_on") Last funding {{ item.last_funding_on | moment("YYYY-MM")}} 
                                          .small.text-muted(v-if="item.total_funding_usd") Total funding {{ "$"  + item.total_funding_usd.toString().formatPrice() }} 
                                          .small.text-muted(v-if="item.hy_rank") Rank {{ item.hy_rank.toLocaleString(undefined) }}
                                          span(v-if="item.is_startup_score")
                                            label Startup score
                                            .badge.badge-secondary().ml-1 {{item.is_startup_score | round }}
                                          
                                          span(v-if="item.relations && item.relations.contact_ids && item.relations.contact_ids.length")
                                            label.mr-2.mt-3.d-block Contacts
                                            
                                            .mb-1(v-for="contact in item.relations.contact_ids.slice(0,3)")
                                              .badge.badge-secondary
                                                router-link(:to="'/ecosystem/orgs/' + item._id") {{contact.first_name}} {{contact.last_name}} <span v-if="contact.first_name || contact.last_name">&mdash;</span> {{contact.position}}
                                          
                                          div(v-if="item.index_space && item.index_space.projects")
                                            label.mr-2.mt-1.d-block Projects
                                            
                                            .mb-1(v-for="po in item.index_space.projects")
                                              router-link(:to="'/projects/' + po._id + '/board'", v-if="po._id")
                                                .badge.badge-secondary  {{po.name}} 
          
                                          .badge.badge-primary.mr-1(v-for="it in item.crunchbase_category_list") {{it}}
                                          //.badge.badge-secondary.mr-1(v-for="it in item.category_groups_list") {{it}}
              .row.mt-5
                .col-12(v-if="$store.getters.search.rows")
                  infinite-loading(@infinite="getData")
                    <div slot="spinner">Loading...</div>
                    <div slot="no-more">No more results</div>
                    <div slot="no-results">No results found for your query.</div>
                
              
                    i.text-muted.small {{$store.getters.search.total_rows}} results found. All results are view- and exportable in production version.
              
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import TaxonomyDisplay from '@/components/TaxonomyDisplay'
import BenchmarkScatter from '@/components/BenchmarkScatter'




import SearchFilter from '@/views/ecosystem/SearchFilter'
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'
import { mapFields } from 'vuex-map-fields';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  
  name: 'home',
  
  data: function () {
    return {
      currentPage: 1,
      s: {},

      attributes_options:  [],
      selectAll: false,
      categories_options: ["Alternative Medicine","Assisted Living","Assistive Technology","Biopharma","Cannabis","Child Care","Clinical Trials","Cosmetic Surgery","Dental","Diabetes","Dietary Supplements","Elder Care","Electronic Health Record (EHR)","Emergency Medicine","Employee Benefits","Fertility","First Aid","Genetics","Health Care","Health Diagnostics","Home Health Care","Hospital","Medical","Medical Device","mHealth","Nursing and Residential Care","Nutraceutical","Outpatient Care","Personal Health","Psychology","Rehabilitation","Therapeutics","Veterinary","Wellness"],
      attributes_selected: [],
      q: "",
     
      uuid: null,
      event_results: [],
      sort_options: [{"text": "Last funding date", "value": "last_funding_on"}, {"text": "Total funding", "value": "total_funding_usd"},  {"text": "Entry Date", "value": "source_first_created_at"}, {"text": "Founding Date", "value": "founding_date"}, {"text": "Company Rank", "value": "hy_rank"}],

      selected: [],
      selectedNode: {},
      colors: {"retrieval": "rgba(200, 200, 200, 0.8)", "extraction": "rgba(200, 200, 200, 0.8)", "knowledge": "rgba(200, 200, 200, 0.8)", undefined: "rgba(200, 200, 200, 0.8)"},
      sizes: {"Org": 70, "Text": 10, "Topic": 20, "Website": 25, "Webpage": 15, "ImprintInfos": 10},
      current_actions: [{"label": "Remove", "name": "remove"}],
      graphName: "",

      sticky_options: {
        topSpacing: 120,
        resizeSensor: false,
        bottomSpacing: 100
       
      }
    }
  },
filters: {
      round: function(x) {
        if (!x) return '';
        return Math.round(x*100)/100;
      }
},
  mounted: function() {
  
    this.host = Config.config.base_host;
    this.attributes_options = this.instance_config.data_fields;
    this.attributes_selected = this.attributes_options;
   
  },

  created() {
   
  },

  beforeDestroy(){
   
  },


  computed: {
      ...mapFields([
        'query',
        'query.query_type',
        'search.rows',
        'instance_config'
      ]),

  },

  methods: {

    ucfirst(text) {
      if (!text) return text;
      return text[0].toUpperCase() + text.slice(1);
    },

    select_all_none() {
      if(!this.selectAll) {
        this.$store.state.selectedItems = [];
      } else {
        this.$store.state.selectedItems = this.$_.cloneDeep(this.$store.getters.search.rows);
      }
      
      this.$forceUpdate();
    },


    toggle_selected(item) {
      
      console.log(this.$store.getters.selectedItems.map((x)=>{return x._id}));
      if(this.$store.getters.selectedItems.map((x)=>{return x._id}).indexOf(item._id)>-1) {
        this.$store.dispatch("remove_selected", [item]);
      } else {
        this.$store.dispatch("add_selected", [item]);
      }
    },
    
    heatMapColorforValue(percentage, hue0 = 120, hue1 = 0) {
      var hue = ((1 - percentage) * (hue1 - hue0)) + hue0;
      return 'hsl(' + hue + ', 100%, 50%, 0.2)';
    },


   compareFilter() {
      
      if(!this.query.filter_init) return false;
      const filtered1 = Object.keys(this.query.filter_init )
      .filter(key => !this.query.filter_compare_exclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.query.filter_init[key];
        return obj;
      }, {});

      const filtered2 = Object.keys(this.query.filter )
      .filter(key => !this.query.filter_compare_exclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.query.filter[key];
        return obj;
      }, {});

      
      return this.$_.isEqual(filtered1, filtered2);
    },


    calc_data(value, field) {

      function scale(val, max, min) { return (val - min) / (max - min); }
      
      let all_val = this.rows.map(row => {
        if(row &&  row.custom_data && row.custom_data[field] != undefined) {
          return row.custom_data[field];
        }
      }).filter(x => x != null);

      let min = Math.min(...all_val);
      let max = Math.max(...all_val);
      
      return scale(value, max, min);
    
    },

    searchIntro() {
      this.$store.dispatch("start_loading");
      this.query.filter.full_query = ['sustainability']; 
      this.getDataClear(true);
    },


    getData(state) {

        if(this.compareFilter()) {
          return;
        }
  
        if(this.$route.query.saved_query_id) {
          this.$store.dispatch("load_query", {query_id: this.$route.query.saved_query_id, "scroll_state": state});
        } else {
          this.$store.dispatch("search", state);
        }
        
        
        
        this.$forceUpdate();
       
        
    },

     getDataClear() {
        
        
        this.current_filter = this.$_.cloneDeep(this.query.filter);
        
        this.query.filter.bookmark_hy = null;
        this.query.filter.bookmark_ecosystem = null;

 
        this.$store.dispatch("search");
        //this.$store.dispatch("stop_loading");
     
        window.scrollTo(0,0);

    },

  },
   directives: {
    "sticky": VueStickyDirective
  },
  components: {
      InfiniteLoading,
      SearchFilter,
      TaxonomyDisplay,
      BenchmarkScatter
  }
}

</script>
<style lang="scss" scoped>


.text-clamp {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}


.badge.badge-secondary {
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 300px;
  display: inline-block;
  position: relative;
}
 
.compare-table {
  font-size: 0.875em;
  td {
    width: 150px !important;
    min-width: 150px !important;  
  }

  td.desc {
    width: 200px !important;
    min-width: 200px !important;
    padding: 5px; 
  }
  
}

</style>
