<template lang="pug">
  .search-filter
    b-form(autocomplete="off")
        
        .row
          .col-6
    
        

         

            FilterGroup(title="Basic Info" :collpased="true").mt-3

              
              b-form-group(id="input-group-founded_on", label="Foundation year", label-for="founded_on", label-size="sm")
                  b-input-group(id="input-group-last_funding_on")
                    b-input(type="number" placeholder="From", size="sm", v-model="founded_on_from", @change="getData()").mr-3
                    div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                    b-input(type="number" placeholder="To", size="sm", v-model="founded_on_to", @change="getData()").ml-3


              b-form-group(id="input-group-employee-number", label="Employee Number", label-for="employee-number", label-size="sm")
                  
                  v-select(id="employee-number"
                        @input="getData()"
                        :options="employee_count_options",
                        :multiple="true"
                        label="label"
                        :close-on-select="true"
                        :reduce="x => x"
                        :select-on-tab="true"
                        v-model="employee_count").small
                        template(slot="no-options") Type to search
                        template(slot="option" slot-scope="option")
                          | {{ option.label }}
              
              //b-form-group(id="input-group-company-status", label="Company Status", label-for="company-status", label-size="sm")
                  
                  v-select(id="company-status"
                        @input="getData()"
                        :options="['operating', 'closed']",
                        :multiple="true"
                        label="label"
                        :close-on-select="true"
                        :reduce="x => x.value"
                        :select-on-tab="true"
                        v-model="status").small
                        template(slot="no-options") Type to search
                        template(slot="option" slot-scope="option")
                          | {{ option.label }}

            FilterGroup(title="Locations" :collpased="true").mt-3
          
                b-form-group(v-if="country_code_options", id="input-group-country_code", label="Countries", label-for="input-2", label-size="sm")
                    
                    v-select(id="country_code"
                          @input="getData()"
                          :options="country_code_options",
                          :multiple="true"
                          label="label"
                          :close-on-select="true"
                          :reduce="x => x.value"
                          :select-on-tab="true"
                          v-model="country_code").small
                          template(slot="no-options") Type to search
                          template(slot="option" slot-scope="option")
                            | {{ option.label }}

                b-form-group(v-if="region_options", id="input-group-region", label="Regions", label-for="input-2", label-size="sm")
                    v-select(id="region"
                          :options="region_options",
                          :multiple="true"
                          @input="getData()"
                          :close-on-select="true"
                          :select-on-tab="true"
                          v-model="region").small

                b-form-group(v-if="city_options", id="input-group-city", label="Cities", label-for="input-2", label-size="sm")
                    v-select(id="city"
                          :options="city_options",
                          :multiple="true"
                          @input="getData()"
                          :filterable="true"
                          :close-on-select="true"
                          :select-on-tab="true"
                          v-model="city"
                          ).small

          .col-6
            FilterGroup(title="Certification tags" :collpased="true", v-if="instance_config.app_config.base.version == 'health'").mt-3

              b-form-group(id="tags-group", label="Certification keywords found on website", label-for="categories", label-size="sm")
                v-select(id="tags"
                          size="sm", 
                          :options="instance_config.organization_types",
                          :multiple="true"
                          @input="getData()"
                          :close-on-select="false"
                          :select-on-tab="true"
                          v-model="tags").small

            FilterGroup(title="Similar to", v-if="search.includes('sim_search')", :collpased="true").mt-3
        
              b-form-group(id="tags-group", label="Positive Examples", label-for="categories", label-size="sm")
                CompanyTextSelect(v-model="sim_search", @input="getData()")#sim-search-ta.small
              
              //b-form-group(id="tags-group", label="Negavitve Examples", label-for="categories", label-size="sm")
                CompanyTextSelect(v-model="sim_search_neg", @input="getData()")#sim-search-ta.small

            FilterGroup(title="Financials" :collpased="true").mt-3

                    //b-form-group(id="input-group-current_stage", label="Investors", label-for="input-2", label-size="sm")
                      v-select(id="city"
                            :options="current_funding_stage",
                            :multiple="true"
                            @input="getData()"
                            :filterable="true"
                            :close-on-select="true"
                            :select-on-tab="true"
                            v-model="city"
                            ).small

                    b-form-group(id="input-group-revenue", label="Revenue", label-for="revenue", label-size="sm", v-if="instance_config.revenue_filter")
                      
                      b-input-group(id="input-group-last_funding_on")
                        b-input(type="number" placeholder="From", size="sm", v-model="current_revenue_usd_from", @change="getData()").mr-3
                        div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                        b-input(type="number" placeholder="To", size="sm", v-model="current_revenue_usd_to", @change="getData()").ml-3
                  

                    b-form-group(id="input-group-current_stage", label="Current Stage", label-for="input-2", label-size="sm")
                      v-select(id="city"
                            :options="last_funding_type_options",
                            :multiple="true"
                            @input="getData()"
                            :filterable="true"
                            :reduce="x => x.value"
                            :close-on-select="true"
                            :select-on-tab="true"
                            v-model="last_funding_type"
                            ).small

       
                    b-form-group(id="input-group-founded_on", label="Total funding ($US)", label-for="founded_on", label-size="sm")
                      b-input-group(id="input-group-last_funding_on")
                        b-input(type="number" placeholder="From", size="sm", v-model="total_funding_usd[0]", @change="getData()").mr-3
                        div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                        b-input(type="number" placeholder="To", size="sm", v-model="total_funding_usd[1]", @change="getData()").ml-3


                    
                    b-form-group(id="input-group-last_funding_on", label="Last funding", label-for="input-2", label-size="sm")
                      b-input-group(id="input-group-last_funding_on")
                      
                        input(type="date" v-model="last_funding_on_from" @change="getData()", style="border: 1px solid; padding: 2px").mr-3
                       
                        div(style="margin-top: 5px") &dash;
                        input(type="date" v-model="last_funding_on_to" @change="getData()", style="border: 1px solid; padding: 2px").ml-3
            

            FilterGroup(title="" :collpased="true", v-if="instance_config.app_config.base.version != 'energy'").mt-3
                    b-form-group(id="is_funded_projects", label="Public projects funding", label-for="input-2", label-size="sm")
                      b-checkbox(v-model="is_funded_projects", @change="getData()")
                        | Has funded projects

            FilterGroup(title="Debug" :collpased="true", v-if="$store.state.user.instance == 'development'").mt-3
                    b-form-group(id="is_hr", label="Company registry", label-for="input-2", label-size="sm")
                      b-checkbox(v-model="is_hr", @change="getData()")
                        | HR
                        
                    
                    //b-form-group(id="input-group-current_stage", label="Number funding rounds", label-for="input-2", label-size="sm")
                      vue-slider(v-model="number_of_funding_rounds", :data="['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','>15']")
                      //b-input(type="range" min="1" max="20" class="slider" id="myRange", style="background-color: transparent;")

                    
                
            //FilterGroup(title="Sources", :collpased="true").mt-3
                
                b-input-group()
                  b-form-checkbox-group(@input="getData()", :options="sources_filter", v-model="sources", id="filter-group-sources" stacked)
               
               
          
</template>

<script>
// @ is an alias to /src

import Config from '@/config'
import Vue from 'vue'

import FilterGroup from '@/components/FilterGroup'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import CompanyTextSelect from '@/components/CompanyTextSelect'
import TaxonomySelect from '@/components/TaxonomySelect'
import WatchlistSelect from '@/components/WatchlistSelect'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import TopicSelect from '@/components/TopicSelect'

import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'SearchFilter',

  data: function () {
    return {
      sources_filter: [{"text": "My Ecosystem", "value": "ecosystem"}, {"text": "External sources", "value": "hy"}],
      project_config: [{"name": "Industry", "topic_type": "industry", "adv": []},{"name": "Enterprise Function", "topic_type": "enterprise_function", "adv":  []},{"name": "Vertical",  "topic_type": "vertical", "adv":  []}, {"name": "Use case",  "topic_type": "use_case", "adv":  []}],
      info_filter: [{"text": "German registry startups only", "value": "is_startup"}],
      keyword_options: [],
      use_query: false,
      employee_count_options: ['1-10', '11-50', '51-100', '101-250', '501-1000', '1001-5000', '5001-10000', '10000+', 'unknown'],
      saved_queries: null,
      load_query_show: false,
    
      save_query_show: false,
      load_query: null,
      save_query: null,
      add_filter: null,
      additional_queries: [],
      adv: false
    }
  },

  url: {
   
  },
  watch: {
   
  },

  mounted: function() {
  
      this.host = Config.config.base_host;
      if(!this.selected) {
        this.selected = "No similarity search";
      }
      this.$store.dispatch("get_filter_options");
      
      this.getQueries();
      
      if(this.$route.query.topic_id) {
      
        this.sortBy = 'founding_date';
        this.sources = 'hy';
        //this.getData();
      } else if(this.$route.query.keyword) {
        this.keywords = [this.$route.query.keyword];
        //this.getData();
      } else {

        if(this.$route.query && this.$route.query.saved_query_id) {
        
          Object.keys(this.filter.custom_data).forEach((key) => {
            this.filter.custom_data[key] = [];
          });

          this.do_load_query(this.$route.query.saved_query_id);
        } else {

         
          Object.keys(this.filter.custom_data).forEach((key) => {
            this.filter.custom_data[key] = [];
            this.filter.custom_data[key].push({"op": null, "q": []});
          });
          //this.getData();
        }


      }
   
      

      
  },

  computed: {

    ...mapFields([

        'instance_config',
        'query.selected',
        
        'query.domains',
        'query.similar_list',
        'instance_config.app_config.organizations.search',
        'query.filter',
        'query.filter.keywords',
        'query.filter.full_query',
        'query.filter.sim_domains',
        'query.filter.sim_search',
        'query.filter.sim_search_neg',
        'query.filter.q',
        'query.filter.city',
        'query.filter.info',
        'query.filter.custom_data',
        'query.filter.country_code',
        'query.filter.crunchbase_category_list',
        'query.filter.crunchbase_category_groups_list',
        'query.filter.region',
        'query.filter.topic_ids',
        'query.filter.tags',
        'query.filter.watchlist_id',
        'query.filter.categories',
        'query.filter.project_id',
        'query.filter.sources',
        'query.filter.name_str',
        'query.filter.name_list',
        'query.filter.total_funding_usd',
        'query.filter.employee_count',
        'query.filter.founded_on_from',
        'query.filter.founded_on_to',
        'query.filter.current_revenue_usd_from',
        'query.filter.current_revenue_usd_to',
        'query.filter.number_of_funding_rounds',
        'query.filter.last_funding_type',  
        'query.filter.last_funding_on_from',
        'query.filter.last_funding_on_to',
        'query.filter.is_hr',
        'query.filter.is_funded_projects',
        'query.filter.sbti',
        'query.filter.cdp_score',  
        'query.filter.sortBy',
      
        'filter_options.crunchbase_category_list_options',
        'filter_options.tags_options',
        'filter_options.crunchbase_category_groups_list_options',
        'filter_options.categories_options',
        'filter_options.last_funding_type_options',

        'filter_options.country_code_options',
        'filter_options.city_options',
        'filter_options.region_options',
        'filter_options.sbti_options',
        'filter_options.cdp_score_options',

        
        'query.filter.bookmark_hy',
        'query.filter.bookmark_ecosystem'
    ]),
  },
  
  methods: {

    handleClick(ev) {
      console.log(ev);
      ev.stopPropagation();
    },

    getUUID() {
      return  Math.random();
    },

    updateSticky() {
      this.$emit('updatesticky');
    },

    deleteField(item_list, item) {
      item_list.splice(item_list.indexOf(item), 1);
      this.getData();
    },

    do_save_query(query_name, filter) {
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/queries_v1`, {"name": query_name, "filter": filter}).then(res => {
        this.$router.replace({query: { "saved_query_id": res.body._id }});
        this.getQueries();
        this.load_query = res.body._id;
        this.load_query_show = true;
        this.save_query_show = false;
        this.$store.dispatch("stop_loading");
      });
    },

    do_change_query(query_name, filter, id) {
      this.$store.dispatch("start_loading");
        
      Vue.http.put(`${Config.config.base_host}/queries_v1/${id}`, {"_id": id, "filter": filter}).then(res => {
        this.load_query = res.body._id;
        this.load_query_show = true;
        this.save_query_show = false;
        this.$store.dispatch("stop_loading");
      });
    },

    do_select_query(query_id) {
       Object.keys(this.filter.custom_data).forEach((key) => {
          this.filter.custom_data[key] = [];
        });
        this.do_load_query(query_id);
    },

    do_load_query(query_id) {
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/queries_v1/${query_id}`).then(res => {
        this.load_query = res.body._id;
        this.load_query_show = true;
       
        if(!this.$route.query.saved_query_id) {
          this.$router.replace({query: { "saved_query_id": res.body._id }});
        }

        delete res.body.filter.bookmark_hy;
        delete res.body.filter.bookmark_ecosystem;
        if(res.body.filter.q) {
          this.use_query = true;
        }

        this.filter = res.body.filter;
      
        this.$forceUpdate();
        
       
      });
    },

    getQueries() { 
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/queries_v1`).then(res => {
        this.saved_queries = res.body;
        this.$store.dispatch("stop_loading");
      });
    },

    getData() {

        this.$store.state.search.rows = null;
        this.bookmark_hy = null;
        this.bookmark_ecosystem = null;
        //this.domains = this.$store.getters.project.data.map(x=>x.domain).join("\n");
     
        /*this.$router.push({
          path: this.$route.path,
          query: this.$store.state.query.filter
        });*/


      
        this.$emit("search");
        
        // if(this.selected.indexOf("No sim") > -1) {
          
        //   this.$store.dispatch("search");
        // } else if(this.selected.indexOf("Project:") > -1) {
        //   console.log(this.$store.state.query.domains);
        //   this.domains = this.$store.getters.project.data.map(x=>x.domain).join("\n");
        //   this.$forceUpdate();
        //   this.$store.dispatch("search_similar"); 
        // } else {
          
        //   this.$store.dispatch("search_similar");
        // }
                
        //this.display();
    },

  },
  components: {
    FilterGroup,
    ProjectStageSelect,
    TopicKeywordSelect,
    CompanyTextSelect,
    TaxonomySelect,
    WatchlistSelect,
    VueSlider,
    TopicSelect
  }
}

</script>
<style lang="scss" scoped>

.form-group {
  margin-bottom: 5px;
}

</style>
